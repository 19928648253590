import React, { useState } from 'react';
import Select from 'react-select';
import GolLogo from '../assets/logos/gol.png';
import AzulLogo from '../assets/logos/azul.png';
import LatamLogo from '../assets/logos/latam.png';
import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale'; // Se você quiser em português brasileiro


// Map de logos para as companhias aéreas
const airlineLogos = {
  'Gol': GolLogo,  // Adicione o URL da logo da Gol
  'Azul': AzulLogo, // Adicione o URL da logo da Azul
  'Latam': LatamLogo, // Adicione o URL da logo da Latam
};

function ExploreList({ searches }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });
  const [selectedOrigem, setSelectedOrigem] = useState([]);
  const [selectedDestino, setSelectedDestino] = useState([]);
  const [selectedCompanhia, setSelectedCompanhia] = useState([]);
  const itemsPerPage = 20;

  // Função de ordenação
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Função para ordenação e filtragem
  const filteredSearches = searches
    .filter((search) =>
      (selectedOrigem.length === 0 || selectedOrigem.some((origem) => origem.value === search.origem)) &&
      (selectedDestino.length === 0 || selectedDestino.some((destino) => destino.value === search.destino)) &&
      (selectedCompanhia.length === 0 || selectedCompanhia.some((companhia) => companhia.value === search.airline)) &&
      (search.origem.toLowerCase().includes(searchTerm.toLowerCase()) || 
       search.destino.toLowerCase().includes(searchTerm.toLowerCase()))
    )
    .sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

  // Paginação
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredSearches.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredSearches.length / itemsPerPage);

  // Opções para os filtros de origem, destino e companhia aérea
  const uniqueOrigem = [...new Set(searches.map((search) => search.origem))].map((origem) => ({ value: origem, label: origem }));
  const uniqueDestino = [...new Set(searches.map((search) => search.destino))].map((destino) => ({ value: destino, label: destino }));
  const uniqueCompanhia = ['Gol', 'Azul', 'Latam'].map((companhia) => ({ value: companhia, label: companhia }));

  return (
    <div className="explore-list">
      <h2 className="explore-title">Buscas Recentes</h2>

      {/* Campo de busca por texto */}
      <input
        type="text"
        placeholder="Buscar por origem ou destino"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />

      {/* Filtros com react-select (multiselect com autocomplete) */}
      <div className="filters">
        <div className="filter">
          <label>Origem:</label>
          <Select
            isMulti
            options={uniqueOrigem}
            value={selectedOrigem}
            onChange={setSelectedOrigem}
            placeholder="Selecione a(s) origem(ns)"
          />
        </div>

        <div className="filter">
          <label>Destino:</label>
          <Select
            isMulti
            options={uniqueDestino}
            value={selectedDestino}
            onChange={setSelectedDestino}
            placeholder="Selecione o(s) destino(s)"
          />
        </div>

        <div className="filter">
          <label>Companhia Aérea:</label>
          <Select
            isMulti
            options={uniqueCompanhia}
            value={selectedCompanhia}
            onChange={setSelectedCompanhia}
            placeholder="Selecione a(s) companhia(s)"
          />
        </div>
      </div>

      {currentItems.length > 0 ? (
        <table className="search-table">
          <thead>
            <tr>
              <th onClick={() => requestSort('flight_date')}>Data</th>
              <th onClick={() => requestSort('ultimaVisualizacao')}>Última Visualização</th>
              <th onClick={() => requestSort('origem')}>Partida</th>
              <th onClick={() => requestSort('destino')}>Chegada</th>
              <th>Companhia Aérea</th>
              <th onClick={() => requestSort('economy_points')}>Econômica</th>              
              <th>Link</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((search) => (
              <tr key={search.id}>
                <td>{new Date(search.flight_date).toLocaleDateString()}</td>
                <td>  
                  {/* Última Visualização formatada corretamente, removendo "há cerca de" */}
                  {formatDistanceToNow(new Date(search.timestamp), { addSuffix: true, locale: ptBR }).replace('há cerca de ', '')} atrás
                </td>
                <td>{search.origem}</td>
                <td>{search.destino}</td>
                <td>
                  <img src={airlineLogos[search.airline]} alt={search.airline} style={{ width: '50px' }} />
                </td>
                <td>{search.economy_points ? `${search.economy_points} pts` : 'Indisponível'}</td>
                <td>
                  <a href={`${search.url}`} target="_blank" rel="noopener noreferrer">Visitar Site</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>Nenhuma busca recente encontrada.</p>
      )}

      <div className="pagination">
        {[...Array(totalPages)].map((_, i) => (
          <button key={i} onClick={() => setCurrentPage(i + 1)} className="page-btn">
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
}

export default ExploreList;