// src/components/UpdatePassword.js
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../config';
import './UpdatePassword.css'; // Importa o CSS para o componente de atualização de senha

const UpdatePassword = () => {
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const { token } = useParams(); // Token recebido via URL

  const handleUpdatePassword = async () => {
    try {
      const response = await axios.post(`${API_URL}/reset/${token}`, { password });
      setMessage("Senha atualizada com sucesso!"); // Mensagem de sucesso
      setIsSuccess(true);
      setPassword(''); // Limpa o campo de senha
    } catch (error) {
      console.error("Erro ao atualizar senha:", error.response?.data?.message || error.message);
      setMessage("Ocorreu um erro ao atualizar a senha. Tente novamente."); // Mensagem de erro
      setIsSuccess(false);
    }
  };

  return (
    <div className="update-password-container">
      <h2 className="update-password-title">Atualizar Senha</h2>
      <div className="input-group">
        <input
          className="update-password-input"
          type="password"
          placeholder="Nova senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="update-password-button" onClick={handleUpdatePassword}>
          Atualizar Senha
        </button>
      </div>
      {message && (
        <p className={`message ${isSuccess ? 'success' : 'error'}`}>{message}</p>
      )}
    </div>
  );
};

export default UpdatePassword;
