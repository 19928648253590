// src/components/ResetPassword.js
import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import './ResetPassword.css'; // Importa o CSS para o componente de redefinição de senha

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const handleResetPassword = async () => {
    try {
      const response = await axios.post(`${API_URL}/reset_password`, { email });
      setMessage("Email de redefinição de senha enviado com sucesso!"); // Mensagem de sucesso
      setIsSuccess(true);
      setEmail(''); // Limpa o campo de email
    } catch (error) {
      console.error("Erro ao resetar senha:", error.response?.data?.message || error.message);
      setMessage("Ocorreu um erro ao enviar o email. Tente novamente."); // Mensagem de erro
      setIsSuccess(false);
    }
  };

  return (
    <div className="reset-password-container">
      <h2 className="reset-password-title">Reset de Senha</h2>
      <div className="input-group">
        <input
          className="reset-password-input"
          type="email"
          placeholder="Digite seu email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button className="reset-password-button" onClick={handleResetPassword}>
          Enviar Email
        </button>
      </div>
      {message && (
        <p className={`message ${isSuccess ? 'success' : 'error'}`}>{message}</p>
      )}
    </div>
  );
};

export default ResetPassword;
