// Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import './Header.css'; // Importa o CSS do Headerimport { useAuth } from '../AuthContext';
import axios from 'axios';
import { API_URL } from '../config';
import { useNavigate } from 'react-router-dom';


function Header() {
  const { user, logout } = useAuth(); // Obtém o usuário e a função de logout
  const navigate = useNavigate();  // Hook para redirecionamento
  

  const handleLogout = async () => {
    try {
      const response = await axios.get(`${API_URL}/logout`); // Faz a chamada para a API
      console.log(response.data);
      logout(); // Limpa os dados do usuário no contexto
      navigate('/index'); // Redireciona para a página de login
    } catch (error) {
      console.error("Erro no logout:", error.response?.data?.message || error.message);
    }
  };

  return (
    <header className="header">
      <div className="container">
          <h1 className="title"><a href="/explore">Airmile</a></h1>
          <nav className="nav">
              <ul className="nav-list">
              {user ? (
                  <>
                    <li className="nav-item">{user.email}</li>
                     <li className="nav-item"><a href='#' onClick={handleLogout}>Sair</a></li> {/* Botão de logout */}
                  </>
                ) : (
                  <>
                    <li className="nav-item"><a href="/login">Login</a></li>
                    <li className="nav-item"><a href="/register">Cadastre-se</a></li>
                  </>
                )}
              </ul>
          </nav>
      </div>
    </header>
  );
}

export default Header;