// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext'; // Importa o AuthProvider
import Header from './components/Header';
import ExploreList from './components/ExploreList';
import Login from './components/Login';
import Register from './components/Register';
import ResetPassword from './components/ResetPassword';
import UpdatePassword from './components/UpdatePassword';

const url = process.env.REACT_APP_API_URL || "";

function App() {
  const [searches, setSearches] = useState([]);

  useEffect(() => {
    fetchSearches();
  }, []);

  const fetchSearches = async () => {
    try {
      const response = await fetch(url + '/api/explore');
      if (!response.ok) {
        throw new Error('Erro ao buscar dados');
      }
      const data = await response.json();
      setSearches(data);
    } catch (error) {
      console.error('Erro ao buscar pesquisas:', error);
    }
  };

  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Header />
          <main>
            <Routes>
              <Route path="/login" element={<PrivateRoute component={Login} />} />
              <Route path="/register" element={<PrivateRoute component={Register} />} />
              <Route path="/reset_password" element={<ResetPassword />} />
              <Route path="/reset/:token" element={<UpdatePassword />} />
              <Route path="/explore" element={<ExploreList searches={searches} />} />
              {/* Adicione mais rotas conforme necessário */}
            </Routes>
          </main>
        </div>
      </Router>
    </AuthProvider>
  );
}

// Componente para proteger rotas que não devem ser acessadas se o usuário estiver logado
const PrivateRoute = ({ component: Component }) => {
  const { user } = useAuth();
  return user ? <Navigate to="/explore" replace /> : <Component />;
};

export default App;
