// src/components/Register.js
import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import { useNavigate } from 'react-router-dom'; // Importa o hook useNavigate
import './Register.css'; // Importa o CSS para o componente de registro

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate(); // Inicializa o hook useNavigate

  const handleRegister = async () => {
    try {
      const response = await axios.post(`${API_URL}/register`, { email, password });
      setMessage("Registro realizado com sucesso! Você pode fazer login agora."); // Mensagem de sucesso
      setIsSuccess(true); // Define como sucesso
      setEmail(''); // Limpa o campo de email
      setPassword(''); // Limpa o campo de senha

      // Aguarda um segundo e redireciona para a página de login
      setTimeout(() => {
        navigate('/login'); // Redireciona para a página de login
      }, 1000); // Tempo em milissegundos (1 segundo)
    } catch (error) {
      // Mensagem de erro
      if (error.response && error.response.data) {
        setMessage(error.response.data.message);
      } else {
        setMessage("Ocorreu um erro ao registrar. Tente novamente.");
      }
      setIsSuccess(false); // Define como erro
    }
  };

  return (
    <div className="register-container">
      <h2 className="register-title">Registro</h2>
      <div className="input-group">
        <input
          className="register-input"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="register-input"
          type="password"
          placeholder="Senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="register-button" onClick={handleRegister}>Registrar</button>
      </div>
      {message && (
        <p className={`message ${isSuccess ? 'success' : 'error'}`}>{message}</p>
      )}
    </div>
  );
};

export default Register;
