// src/components/Login.js
import React, { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { API_URL } from '../config';
import { useNavigate } from 'react-router-dom'; 
import { useAuth } from '../AuthContext'; // Importa o contexto de autenticação
import './Login.css'; 

const Login = () => {
  const { login } = useAuth(); // Obtém a função de login do contexto
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setError(null);
    try {
      const response = await axios.post(`${API_URL}/login`, { email, password });
      login({ email });
      navigate('/explore');
    } catch (error) {
      setError(error.response?.data?.message || "Erro no login");
      console.error("Erro no login:", error.response?.data?.message || error.message);
    }
  };

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    const token = credentialResponse.credential;
    try {
      const response = await axios.post(`${API_URL}/login/google`, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      login({ email: response.data.email }); 
      navigate('/explore');
    } catch (error) {
      setError(error.response?.data?.message || "Erro no login com Google");
      console.error("Erro no login com Google:", error.response?.data?.message || error.message);
    }
  };

  return (
    <div className="login-container">
      <h2 className="login-title">Login</h2>
      {error && <div className="error-message">{error}</div>}
      <div className="input-group">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="login-input"
        />
        <input
          type="password"
          placeholder="Senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="login-input"
        />
      </div>
      <button onClick={handleLogin} className="login-button">Entrar</button>
      <h3 className="alternative-login">Ou entre com Google</h3>
      <GoogleLogin
        onSuccess={handleGoogleLoginSuccess}
        onFailure={(error) => console.error("Erro ao conectar com Google:", error)}
        className="google-login"
      />
    </div>
  );
};

export default Login;
